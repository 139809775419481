<template>
    <el-dialog
        :title="$t('economy.create_invoice')"
        :visible.sync="modalCreateInvoice"
        :before-close="closeModal"
        width="900px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <el-form ref="formData" :model="formData" label-position="top" :rules="rules" class="px-16">
            <div class="grid grid-cols-2">
                <el-form-item :label="$t('economy.issue_at')" class="col">
                    <el-date-picker
                        v-model="formData.issueAt"
                        type="date"
                        :clearable="false"
                        :editable="false"
                        :picker-options="{ firstDayOfWeek: 1 }"
                        :placeholder="$t('economy.issue_at')"
                        format="dd.MM.yyyy"
                    />
                </el-form-item>
                <el-form-item :label="$t('economy.due_date')" prop="dueDate" class="col w-full">
                    <el-date-picker
                        v-model="formData.dueDateAt"
                        type="date"
                        :clearable="false"
                        :editable="false"
                        class="w-full"
                        :picker-options="{ firstDayOfWeek: 1 }"
                        :placeholder="$t('economy.due_date')"
                        format="dd.MM.yyyy"
                    />
                </el-form-item>
            </div>

            <el-form-item class="mt-2" style="margin-bottom: 0">
                <el-checkbox v-model="collect_calendar_items" class="mb-0">
                    {{ $t('economy.collect_events_from_calendar') }}
                </el-checkbox>
            </el-form-item>

            <el-form-item :label="$t('economy.calendar_range')" prop="startDate" class="w-full">
                <el-date-picker
                    v-model="formData.calendarDateRange"
                    type="daterange"
                    class="w-full"
                    :clearable="true"
                    :editable="false"
                    :disabled="!collect_calendar_items"
                    :picker-options="{ firstDayOfWeek: 1 }"
                    :placeholder="$t('economy.calendar_range')"
                    format="dd.MM.yyyy"
                />
            </el-form-item>
            <el-transfer
                v-model="formData.billingInformationUuid"
                filterable
                :titles="[$t('economy.all_clients'), $t('economy.selected')]"
                :filter-method="filterMethod"
                :filter-placeholder="$t('common.search')"
                :data="billingInformationToTransfer"
            />
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('creating')" @click="createInvoice">{{ $t('common.create') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>

import verifyDueDate from './Validator';

export default {
    props: {
        modalCreateInvoice: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        const dueDateValidator = (rule, value, callback) => verifyDueDate(value, this.formData.issueAt, this.formData.dueDateAt, callback, this);

        return {
            billingInformationToTransfer: [],
            collect_calendar_items:       true,
            formData:                     {
                issueAt:                this.$dayjs(),
                dueDateAt:              this.$dayjs().add(14, 'days'),
                billingInformationUuid: [],
                calendarDateRange:      [
                    this.$dayjs().startOf('month'),
                    this.$dayjs().endOf('month'),
                ],
            },
            rules: {
                dueDate: [{ validator: dueDateValidator, trigger: 'blur' }],
            },
        };
    },

    created() {
        this.getBillingInformationToDropdown();
    },

    methods: {
        async getBillingInformationToDropdown() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/billing_information`);
            this.billingInformationToTransfer = data;
        },

        async createInvoice() {
            // @Filip
            this.$refs.formData.validate(async valid => {
                if (!valid) return false;
                this.$wait.start('creating');

                let calendarRange = null;
                if (this.formData.calendarDateRange) {
                    calendarRange = [
                        this.$dayjs(this.formData.calendarDateRange[0]).unix(),
                        this.$dayjs(this.formData.calendarDateRange[1]).unix(),
                    ];
                }

                try {
                    await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices`, {
                        issue_at:               this.$dayjs(this.formData.issueAt).unix(),
                        due_date_at:            this.$dayjs(this.formData.dueDateAt).unix(),
                        collect_calendar_items: this.collect_calendar_items ? 1 : 0,
                        calendar_range:         calendarRange,
                        billingInformationUuid: this.formData.billingInformationUuid,
                    });
                    this.$notify.success({
                        title:   this.$t('common.success'),
                        message: this.$t('economy.it_will_take_few_seconds_refresh_the_page_to_see_results'),
                    });
                    this.$wait.end('creating');
                    this.closeModal();
                } catch (error) {
                    this.$wait.end('creating');
                }
            });
        },

        filterMethod(query, item) {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },

        closeModal() {
            axios.cancelAll();
            this.$emit('close');
        },
    },
};
</script>
<style>
.el-transfer-panel {
    margin-top: 30px;
    width: 270px;
}
</style>
